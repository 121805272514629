<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="标题：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item label="分类：">
            <el-select v-model="listQuery.category" class="input-width" placeholder="全部" clearable>
              <el-option v-for="item in orderStatusOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    功能区域-->
    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" >新增</el-button>
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
    </div>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="fileTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="分类"  align="center">
          <template #default="scope">{{scope.row.categoryName}}</template>
        </el-table-column>
        <el-table-column label="标题"  align="center">
          <template #default="scope">{{scope.row.title}}</template>
        </el-table-column>
        <el-table-column label="文件名称"  align="center">
          <template #default="scope">{{scope.row.file_name}}</template>
        </el-table-column>
        <el-table-column label="文件类型"  align="center">
          <template #default="scope">{{scope.row.file_type}}</template>
        </el-table-column>
        <el-table-column label="发布时间" align="center">
          <template #default="scope">{{scope.row.release_time}}</template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button size="mini" type="primary" @click="handleUpdate(scope.row)" >设置类别</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEditFlag == true?'编辑':'添加'"
        v-model="dialogVisible"
        width="40%">
      <el-form :model="fileModel"
               ref="fileFrom"
               label-width="150px"
               size="small">
        <el-form-item label="分类：">
          <el-select v-model="fileModel.category" placeholder="请选择" style="width: 80%" >
            <el-option
                v-for="item in orderStatusOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="文件：" v-if="this.isEditFlag == false">
          <el-upload ref="uploadForm" v-model="fileModel.attachment" drag multiple
                     action="#"
                     :file-list="fileListShow"
                     :auto-upload="false"
                     :before-remove="handleRemove"
                     :on-change="fileChange"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleDialogConfirm('fileFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import {
  batchDeleteFile,
  deleteFile,
  fetchList,
  uploadFile,
  deleteUploadFile,
  deleteBatchUploadFile, updateCategory
} from "@/api/file";
import {fetchAllCate} from "@/api/category";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
  category: null,
};
const defaultStatusOptions = [];

const defaultFile = {
  title: null,
  category: null,
  attachment: null,
  original_name: null,
  file_name: null,
  file_type: null,
  comment: null,
  release_time: null,
};

export default {
  name: 'File',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      orderStatusOptions: [],
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      attachments: [],
      dialogVisible: false,
      isEditFlag: false,
      fileModel: Object.assign({}, defaultFile),
      fileListShow:[],
      fileList:[],
    }
  },
  created() {
    this.getTableList()
  },
  methods: {

    //文件上传
    fileChange(file, fileList) {
      this.fileList.push(file);
      this.fileListShow.push({
        name: file["name"],
      })

    },
    //文件删除
    handleRemove(file, fileList) {

      // 通过file.name或者其他属性来匹配需要删除的文件
      this.fileList = this.fileList.filter(item => item.name !== file.name);
    },
    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
      this.attachments = val.map(v => v.attachment);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },
    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": row.id,
        };
        deleteUploadFile(data);

        deleteFile(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
        };

        //删除附件
        deleteBatchUploadFile(data);

        batchDeleteFile(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "批量删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //列表
    getTableList() {
      this.listLoading = true;
      fetchList(this.listQuery).then(res => {
        if (res.code === '0') {
          this.listLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }

      })

      fetchAllCate().then(res => {
        this.orderStatusOptions = res.data
      })

    },
    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditFlag = false;
      this.fileModel = Object.assign({},defaultFile);
      if(this.$refs["uploadForm"]){
        this.$refs['uploadForm'].clearFiles()  // 清除历史文件列表
      }
      this.fileList = [];
      this.fileListShow = [];

    },
    //修改路由
    handleUpdate(row) {
      this.dialogVisible = true;
      this.isEditFlag = true;
      this.fileModel = Object.assign({},row);
      if(row.category == 0){
        this.fileModel.category = null;
      }
    },
    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (!this.isEditFlag) {
            if (this.fileList.length === 0){
              this.$message.warning('请上传文件');
              return;
            }
          }
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEditFlag) {
              if(this.fileModel.category == null){
                this.fileModel.category = 0;
              }
              updateCategory(this.fileModel).then(res => {
                if (res.code === '0') {
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                  });
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });

            }else {
              const form = new FormData();
              for (let i = 0; i < this.fileList.length; i++) {
                form.append('file[]', this.fileList[i].raw);
              }
              if(this.fileModel.category == null){
                this.fileModel.category = 0;
              }
              form.append('category',this.fileModel.category);
              uploadFile(form).then(response=>{
                if (response.code === '0') {
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                }
                this.dialogVisible =false;
                this.getTableList();
              });
            }
          });
        }
      });
    },
  }
}
</script>
