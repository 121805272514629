import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/file/list.php',
    method : 'post',
    data : data
  })
}


//删除
export function deleteFile(data) {
  return request({
    url : '/file/delete.php',
    method : 'post',
    data : data
  })
}


//批量删除
export function batchDeleteFile(data) {
  return request({
    url : '/file/deleteBatch.php',
    method : 'post',
    data : data
  })
}

//上传文件
export function uploadFile(form) {
  const data = form
  return request({
    url: '/file/uploadFile.php',
    method: 'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    data
  })
}

//删除上传文件
export function deleteUploadFile(data) {
  return request({
    url : '/file/deleteUploadFile.php',
    method : 'post',
    data : data
  })
}


//删除上传文件
export function deleteBatchUploadFile(data) {
  return request({
    url : '/file/deleteBatchUploadFile.php',
    method : 'post',
    data : data
  })
}

//新增
export function createFile(data) {
  return request({
    url : '/file/save.php',
    method : 'post',
    data : data
  })
}


//删除上传文件
export function deleteCreateFile(data) {
  return request({
    url : '/file/deleteCreateFile.php',
    method : 'post',
    data : data
  })
}

//修改
export function updateFile(data) {
  return request({
    url :'/file/update.php',
    method : 'post',
    data : data
  })
}

//修改
export function updateCategory(data) {
  return request({
    url :'/file/updateCategory.php',
    method : 'post',
    data : data
  })
}

